export default {
  data: () => ({
    getDmActivityLoading: false
  }),
  methods: {
    /**
     * @method 获取大麦活动信息
     * @param string activityId 活动ID
     * @returns Promise
     */
    getDmActivity (activityId) {
      return new Promise((resolve, reject) => {
        if (!activityId) reject(new Error('活动ID不能为空'))
        this.getDmActivityLoading = true
        this.$api.activity.getDmActivity({
          activityId
        }).then(res => {
          this.getDmActivityLoading = false
          if (!res.code) {
            resolve(res.data || {})
          } else {
            this.$message.error(res.msg)
            reject(new Error(res.msg))
          }
        }).catch((err) => {
          this.getDmActivityLoading = false
          reject(err)
        })
      })
    },
    /**
     * @method 检测是否需要提交弹窗提醒
     * @param string activityId 活动ID
     * @param string type 检测类型
     * @param Function callback 成功回调
     */
    checkIsDialogConfirm (activityId, type = '', callback) {
      if (!type) throw new Error('请传入检测任务类型')
      this.getDmActivity(activityId).then(data => {
        if (data.needVerify && data.noticeType.includes(type)) {
          this.$dialog.confirm({
            title: "提示",
            message: data.noticeContent || '',
            confirmButtonText: "确定",
            cancelButtonText: "取消",
          }).then(() => {
            callback && callback()
          }).catch(() => {
            console.log('用户点击取消弹窗')
          });
        } else {
          callback && callback()
        }
      })
    }
  }
}
